













































































































































































































































































































































































































































































































































































































































































.z_top {
  overflow: hidden;

  .z_left {
    width: 360px;
    height: 180px;
    display: block;
    float: left;
  }

  .z_right {
    float: left;
    margin-left: 20px;

    p {
      margin-bottom: 10px;
    }

    .z_right_1 {
      font-weight: 600;
      font-size: 18px;
    }

    .z_right_3 {
      span {
        color: #6d6d6d;
      }
    }

    .z_right_4 {
      color: #f56c6c;
      font-weight: 600;
      font-size: 18px;
    }
  }
}

.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}

.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}

.nullIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
}

.level {
  padding: 3px;
  border-radius: 6px;
  color: #333;
  background-color: #e0f2ff;
  min-width: 20px;
  text-align: center;
}

.detail {
  .ellipsis {
    display: inline-block;
    width: 30rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 39px;
  }
}

.zfbzf {
  margin-top: 45px;
  margin-bottom: 25px;
  height: 150px;
}

.dgzf {
  margin-top: 20px;
  height: 200px;

  p {
    line-height: 30px;
  }

  .dgzf_t {
    color: red;

    span {
      font-size: 18px;
      color: #e6a23c;
    }
  }
}

.tsTime {
  margin-top: 20px;
  color: #5c73ff;
}

.tsTime_1 {
  margin-top: 20px;
  color: #F56C6C;
}

.dialog-footer {
  width: 100%;
  border-top: 1px solid #ebebeb;

  .je {
    text-align: left;
    margin: 20px 0;
  }

  .zffs {
    float: left;
    margin-top: 8px;
  }

  .wx_1 {
    float: left;
    cursor: pointer;
    vertical-align: middle;
    width: 106px;
    margin-right: 6px;
  }

  .zfb_1 {
    float: left;
    cursor: pointer;
    vertical-align: middle;
    width: 106px;
    margin-right: 6px;
  }

  .dgzf_1 {
    float: left;
    cursor: pointer;
    vertical-align: middle;
    width: 106px;
  }
}

#qrcode {
  width: 150px;
  height: 150px;
  margin: auto;
  margin-top: 45px;
  margin-bottom: 25px;
}
